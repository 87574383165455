<template>
  <div>
    <div class="uk-margin-top">
      <ul
        class="uk-subnav uk-subnav-pill"
        uk-switcher="animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium"
      >
        <li>
          <a
            href="#"
            uk-tooltip="Data Kandang"
          >
            Data Kandang
          </a>
        </li>
        <li>
          <a
            href="#"
            class="tabs-left-kandang"
            uk-tooltip="Data Mitra"
          >
            Data Mitra
          </a>
        </li>
      </ul>
      <ul class="uk-switcher uk-margin">
        <li>
          <DataKandang :data-cage="dataCage.data" />
        </li>
        <li>
          <DataMitra :data-farm="dataCage.data" />
        </li>
      </ul>
    </div>
    <div class="uk-margin-top">
      <ul
        class="uk-subnav uk-subnav-pill"
        uk-switcher="animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium"
      >
        <li>
          <a
            href="#"
            class="truncate-tabs"
            uk-tooltip="Data Siklus"
          >
            Data Siklus
          </a>
        </li>
        <li>
          <a
            href="#"
            class="tabs-left-kandang truncate-tabs"
            uk-tooltip="Data Grafik"
          >
            Data Grafik
          </a>
        </li>
        <li>
          <a
            href="#"
            class="tabs-left-kandang truncate-tabs"
            uk-tooltip="Riwayat Siklus"
          >
            Riwayat Siklus
          </a>
        </li>
        <li>
          <a
            href="#"
            class="tabs-left-kandang truncate-tabs"
            uk-tooltip="Digital Concent"
          >
            Lembar Persetujuan
          </a>
        </li>
      </ul>
      <ul class="uk-switcher uk-margin">
        <li>
          <DataSiklus
            :data-cycle="dataCage.data"
            :cycles-no-limit="dataCycle"
          />
        </li>
        <li>
          <DataGrafik />
        </li>
        <li>
          <DataRiwayatSiklus
            :data-cage="dataCage.data"
            :cycles-no-limit="dataCycle"
          />
        </li>
        <li>
          <DataDigitalConcent />
        </li>
      </ul>
    </div>
    <modal-log-history />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import DataKandang from './component/ContainerKandang'
import DataMitra from './component/ContainerMitra'
import DataSiklus from './component/ContainerSiklus'
import DataGrafik from './component/ContainerGrafik'
import DataDigitalConcent from './component/ContainerDigitalConcent'
import DataRiwayatSiklus from './component/ContainerRiwayatSiklus'
import ModalLogHistory from './component/ModalLogHistory'

export default {
  components: {
    DataKandang,
    DataMitra,
    DataSiklus,
    DataGrafik,
    DataRiwayatSiklus,
    DataDigitalConcent,
    ModalLogHistory
  },
  props: {
    dataCage: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      dataCycle: {
        cycle: [],
        detail_cage: {}
      }
    }
  },
  computed: {
    ...mapGetters({
      cycles_no_limit: 'cycle/cycles_no_limit'
    })
  },
  watch: {
    cycles_no_limit () {
      this.cekFlag(this.cycles_no_limit)
    }
  },
  methods: {
    cekFlag (data) {
      this.dataCycle = {
        cycle: data.cycle,
        detail_cage: data.detail_cage
      }
      data.cycle.map((item, i) => {
        this.dataCycle.cycle[i].pakan_sak = item.pakan_sak != 0 ? item.pakan_sak : this.setValue(data.cycle_done_input[i].pakan_sak_is_done)
        this.dataCycle.cycle[i].group_deplesi_died = item.group_deplesi_died != 0 ? item.group_deplesi_died : this.setValue(data.cycle_done_input[i].group_deplesi_died_is_done)
        this.dataCycle.cycle[i].group_deplesi_culling = item.group_deplesi_culling != 0 ? item.group_deplesi_culling : this.setValue(data.cycle_done_input[i].group_deplesi_culling_is_done)
        this.dataCycle.cycle[i].group_body_weight_actual = item.group_body_weight_actual != 0 ? item.group_body_weight_actual : this.setValue(data.cycle_done_input[i].group_body_weight_actual_is_done)
      })
    },
    setValue (data) {
      const newData = data == 1 ? 0 : ''
      return newData
    }
  }
}
</script>

<style scoped>
.uk-subnav-pill > .uk-active > a {
  background-color: #ffffff;
  color: #005331 !important;
  border-radius: 10px;
  padding: 15px;
}

.uk-subnav {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  padding: 0;
  margin-bottom: -10px !important;
}

.uk-subnav-pill > * > :first-child {
  padding: 15px;
  background: #E0EBE6;
  color: #85AD9C;
  border-radius: 10px;
}

.uk-margin {
  margin-top: 0px !important;
}
</style>
