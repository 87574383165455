import { render, staticRenderFns } from "./ContainerSiklus.vue?vue&type=template&id=ebaa84b4"
import script from "./ContainerSiklus.vue?vue&type=script&lang=js"
export * from "./ContainerSiklus.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports