<template>
  <div
    id="modal-log-history"
    uk-modal
    esc-close="false"
    bg-close="false"
    container="#container-modal-log-history"
    class="uk-modal-container"
  >
    <div class="uk-modal-dialog uk-modal-body">
      <button
        class="uk-modal-close-default"
        type="button"
        uk-close
      />
      <h4>
        Log History
      </h4>
      <h3>
        {{ cageDetail ? cageDetail.name : '-' }} - {{ cageDetail ? cageDetail.current_activity_name : '-' }}
      </h3>
      <div class="uk-margin-top">
        <div class="uk-overflow-auto border-table">
          <table class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider uk-table-middle">
            <thead class="thead-table-paranje">
              <tr>
                <th
                  class="table-header"
                  style="width: 4%"
                >
                  <span class="uk-text-bold">No </span>
                </th>
                <th
                  class="table-header uk-table-expand uk-width-medium"
                  rowspan="2"
                >
                  <span class="uk-text-bold">Keterangan</span>
                </th>
                <th
                  class="table-header uk-table-expand uk-width-small"
                  rowspan="2"
                >
                  <span class="uk-text-bold">Tanggal dan Waku Ubah</span>
                </th>
                <th
                  class="table-header uk-table-expand uk-width-small"
                  rowspan="2"
                >
                  <span class="uk-text-bold">Diubah Oleh</span>
                </th>
              </tr>
            </thead>
            <template v-if="!is_loading">
              <tbody
                v-if="cageLogHistory.meta.total_count > 0"
                style="border: 1px solid #e5e5e5;"
              >
                <tr
                  v-for="(item, i) in cageLogHistory.data"
                  :key="i"
                  style="background: #fff;"
                >
                  <td>{{ i + 1 }}</td>
                  <td>{{ item.description || '-' }}</td>
                  <td>{{ formatDate(item.updated_at) || '-' }}</td>
                  <td>{{ item.user || '-' }}</td>
                </tr>
              </tbody>
              <empty-table
                v-else
                :colspan="4"
              />
            </template>
            <template v-else>
              <loading-table :colspan="4" />
            </template>
          </table>
        </div>

        <pagination
          :total-data="cageLogHistory.meta.total_count"
          :current-page="cageLogHistory.meta.current_page"
          :change-limit="changeLimit"
          :change-page="changePage"
          :current-limit="cageLogHistory.meta.per_page"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { dateUtcParanjeString } from '@/utils/formater'
import Pagination from '@/components/globals/Pagination'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'

export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination
  },
  data() {
    return {
      is_loading: true,
      cageDetail: null
    }
  },
  computed : {
    ...mapGetters({
      cageLogHistory: 'cage/cageLogHistory',
      getCageDetail: 'cage/cage',
      meta: 'cage/metaLogHistory'
    })
  },
  watch: {
    async getCageDetail() {
      if (this.getCageDetail) {
        this.is_loading = true
        this.cageDetail = this.getCageDetail.data
        this.setMeta({
          ...this.meta,
          log_cycle_id: this.getCageDetail.data.cycle.id
        })
        this.is_loading = false
      }
    },
    async meta() {
      this.is_loading = true
      await this.getCageLogHistory(this.meta)
      this.is_loading = false
    }
  },
  async mounted() {
  },
  methods: {
    ...mapActions({
      getCageLogHistory: 'cage/getCageLogHistory'
    }),
    ...mapMutations({
      setMeta: 'cage/SET_META_LOG_HISTORY'
    }),
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    changePage(value) {
      this.setMeta({
        ...this.meta,
        page: value
      })
    },
    changeLimit(e) {
      this.setMeta({
        ...this.meta,
        page: 1,
        limit: e.target.value
      })
    }
  }
}
</script>

<style scoped>

.uk-modal-body {
  padding: 24px 32px;
}

.uk-modal-dialog {
  text-align: start;
}

.uk-modal-close-default {
  color: #1F2E28;
  top: unset;
  right: 30px;
  width: 32px;
  height: 32px;
}

h4 {
  font-weight: 500;
  font-size: 14px;
  color: #1F2E28;
  margin-bottom: 4px;
}

h3 {
  font-weight: normal;
  font-size: 14px;
  color: #025231;
  margin-top: 16px;
  margin-bottom: 16px;
}

</style>
