<template>
  <div class="uk-card uk-card-default kandang-card">
    <div
      class="uk-child-width-expand@s uk-grid-small"
      uk-grid
    >
      <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Nama Peternakan
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="data.name"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Kode Mitra
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="data.farm_code"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <!-- TODO -->
        <!-- <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">Nama Mitra</div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  class="uk-input"
                  v-model="data.owner_name"
                  type="text"
                  disabled
                >
              </div>
            </div> -->
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Total Kandang
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="data.total_cage"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
      </div>
      <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Kota/Kabupaten
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <div class="uk-form-controls">
              <input
                v-model="data.city"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Tanggal Gabung
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="data.join_date"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Status
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <label-status :status="data.status" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LabelStatus from '@/components/globals/LabelStatus'
import { dateParanjeCompleteString } from '@/utils/formater'

export default {
  components: {
    LabelStatus
  },
  props: {
    dataFarm: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      data: {}
    }
  },
  watch: {
    dataFarm() {
      this.data = {
        name: this.dataFarm.farm.name,
        farm_code: this.dataFarm.farm.farm_code,
        owner_name: this.dataFarm.farm.owner_name,
        total_cage: this.dataFarm.farm.total_cage,
        city: this.dataFarm.farm.city,
        join_date: dateParanjeCompleteString(this.dataFarm.farm.join_date),
        status: this.dataFarm.farm.status
      }
    }
  }
}
</script>
