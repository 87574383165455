<template>
  <div class="uk-card uk-card-default kandang-card">
    <div class="small">
      <bar-chart
        :chart-data="dataPointFeed"
        :options="barLineOptionPointFeed"
      />
    </div>
    <div class="small">
      <bar-chart
        :chart-data="dataFeedIntake"
        :options="barLineOptionFeedIntake"
      />
    </div>
    <div class="small">
      <bar-chart
        :chart-data="dataBodyWeight"
        :options="barLineOptionBodyWeight"
      />
    </div>
    <div class="small">
      <bar-chart
        :chart-data="dataFCR"
        :options="barLineOptionFCR"
      />
    </div>
    <div class="small">
      <bar-chart
        :chart-data="dataIP"
        :options="barLineOptionIP"
      />
    </div>
    <router-link
      class="uk-button uk-button-default"
      to="/admin/kandang-ayam"
    >
      <img
        v-lazy="`${images}/icon/back.svg`"
        alt=""
        class="uk-margin-small-right"
      >
      Kembali
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import BarChart from '@/components/globals/chart/BarLineChart.js'
import { float3Decimals } from '@/utils/formater'

export default {
  components: {
    BarChart
  },
  data () {
    return {
      images: PREFIX_IMAGE,
      dataPointFeed: null,
      dataFeedIntake: null,
      dataBodyWeight: null,
      dataFCR: null,
      dataIP: null,
      barLineOptionPointFeed: {title:{},scales:{yAxes:[{ticks:{}}]}},
      barLineOptionFeedIntake: {title:{},scales:{yAxes:[{ticks:{}}]}},
      barLineOptionBodyWeight: {title:{},scales:{yAxes:[{ticks:{}}]}},
      barLineOptionFCR: {title:{},scales:{yAxes:[{ticks:{}}]}},
      barLineOptionIP: {title:{},scales:{yAxes:[{ticks:{}}]}},
      barLineOptions: {
        responsive: true,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              max: 200,
              min: 0,
              stepSize: 20
            }
          }]
        },
        title: {
          display: true,
          text: 'Daily Intake',
          fontSize: 25
        },
        legend: { display: false }
      },
      greenColor: '#049559'
    }
  },
  computed: {
    ...mapGetters({
      cycles_graph: 'cycle/cycles_graph'
    })
  },
  watch: {
    cycles_graph() {
      if (this.cycles_graph.body_weight.x && this.cycles_graph.body_weight.x.length>0) {
        this.fillData('point_feed', this.cycles_graph.point_feed.x, this.cycles_graph.point_feed.y)
        this.fillData('feed_intake', this.cycles_graph.feed_intake.x, this.cycles_graph.feed_intake.y)
        this.fillData('body_weight', this.cycles_graph.body_weight.x, this.cycles_graph.body_weight.y)
        this.fillData('fcr', this.cycles_graph.fcr.x, this.cycles_graph.fcr.y)
        // this.fillData('ip', this.cycles_graph.ip.x, this.cycles_graph.ip.y)
        this.fillDataNotHaveStandard('ip', this.cycles_graph.ip.x, this.cycles_graph.ip.y)
      }
    }
  },
  methods: {
    formatFloat(number) {
      return float3Decimals(number)
    },
    fillData (type, labelsChart, dataChart) {
      const chartColors=[]
      let maxInput = 5
      for (let index = 0; index < dataChart.input.length; index++) {
        const standardVal = dataChart.standard[index]
        const aboveVal = dataChart.input[index]
        if(type === 'fcr') {
          if(aboveVal >= standardVal){
            chartColors.push('#F92626')
          }else{
            chartColors.push('#049559')
          }
        } else {
          if(aboveVal >= standardVal){
            chartColors.push('#049559')
          }else{
            chartColors.push('#F92626')
          }
        }
      }
      if (dataChart.input) {
        maxInput += Math.max(...dataChart.input)
      }
      const buildData = {
        labels: labelsChart,
        datasets: [
          {
            label: 'Above Standard',
            type: 'bar',
            categoryPercentage: 0.3,
            barThickness: 10,
            backgroundColor: chartColors,
            data: dataChart.input
          }, {
            label: 'Standard Daily Intake',
            type: 'line',
            borderColor: '#045A95',
            backgroundColor: '#045A95',
            fill: false,
            data: dataChart.standard
          }
        ]
      }
      const dataTooltip = {
        enabled: true,
        mode: 'single',
        displayColors: false,
        callbacks: {
          title: () => null,
          label: (t, d) => {
            const day = 'Hari ke : ' + t.xLabel
            const daily = `${t.yLabel>d.datasets[1].data[t.index]?'Above Standard':'Below Standard'} : ${this.formatFloat(t.yLabel)}`
            const standard = 'Standard : ' + this.formatFloat(d.datasets[1].data[t.index])
            if (d.datasets[1].data[t.index] !== t.yLabel) {
              return [day, daily, standard]
            }
            return [day, standard]
          }
        }
      }
      const dataLegend = {
        display: true,
        position: 'bottom',
        labels: {
          generateLabels: () => {
            if(type === 'fcr') {
              return [
                {
                  text: "Above Standard",
                  fillStyle: '#F92626'
                },
                {
                  text: "Below Standard",
                  fillStyle: '#049559'
                },
                {
                  text: "Standard Daily Intake",
                  fillStyle: '#045A95'
                }
              ]
            } else {
              return [
                {
                  text: "Above Standard",
                  fillStyle: '#049559'
                },
                {
                  text: "Below Standard",
                  fillStyle: '#F92626'
                },
                {
                  text: "Standard Daily Intake",
                  fillStyle: '#045A95'
                }
              ]
            }
          }
        }
      }

      switch (type) {
      case 'point_feed':
        this.dataPointFeed = buildData
        this.barLineOptionPointFeed = JSON.parse(JSON.stringify(this.barLineOptions))
        this.barLineOptionPointFeed.tooltips = dataTooltip
        this.barLineOptionPointFeed.legend = dataLegend
        this.barLineOptionPointFeed.scales.yAxes[0].ticks.max = maxInput
        // this.barLineOptionPointFeed.scales.yAxes[0].ticks.stepSize = 20
        this.barLineOptionPointFeed.title.text = 'Daily Intake'
        break
      case 'feed_intake':
        this.dataFeedIntake = buildData
        this.barLineOptionFeedIntake = JSON.parse(JSON.stringify(this.barLineOptions))
        this.barLineOptionFeedIntake.tooltips = dataTooltip
        this.barLineOptionFeedIntake.legend = dataLegend
        this.barLineOptionFeedIntake.scales.yAxes[0].ticks.max = maxInput
        // this.barLineOptionFeedIntake.scales.yAxes[0].ticks.stepSize = 300
        this.barLineOptionFeedIntake.title.text = 'Feed Intake'
        break
      case 'body_weight':
        this.dataBodyWeight = buildData
        this.barLineOptionBodyWeight = JSON.parse(JSON.stringify(this.barLineOptions))
        this.barLineOptionBodyWeight.tooltips = dataTooltip
        this.barLineOptionBodyWeight.legend = dataLegend
        this.barLineOptionBodyWeight.scales.yAxes[0].ticks.max = maxInput
        // this.barLineOptionBodyWeight.scales.yAxes[0].ticks.stepSize = 200
        this.barLineOptionBodyWeight.title.text = 'Body Weight'
        break
      case 'fcr':
        this.dataFCR = buildData
        this.barLineOptionFCR = JSON.parse(JSON.stringify(this.barLineOptions))
        this.barLineOptionFCR.tooltips = dataTooltip
        this.barLineOptionFCR.legend = dataLegend
        this.barLineOptionFCR.scales.yAxes[0].ticks.max = maxInput
        // this.barLineOptionFCR.scales.yAxes[0].ticks.stepSize = 0.2
        this.barLineOptionFCR.title.text = 'Feed Conversion Ratio (FCR)'
        break
      case 'ip':
        this.dataIP = buildData
        this.barLineOptionIP = JSON.parse(JSON.stringify(this.barLineOptions))
        this.barLineOptionIP.tooltips = dataTooltip
        this.barLineOptionIP.legend = dataLegend
        this.barLineOptionIP.scales.yAxes[0].ticks.max = maxInput
        // this.barLineOptionIP.scales.yAxes[0].ticks.stepSize = 0.2
        this.barLineOptionIP.title.text = 'Index Prestasi (IP)'
        break
      default:
        break
      }
    },
    fillDataNotHaveStandard (type, labelsChart, dataChart) {
      let maxInput = 5
      if (dataChart.input) {
        maxInput += Math.max(...dataChart.input)
      }
      const buildData = {
        labels: labelsChart,
        datasets: [
          {
            label: 'Actual Value',
            type: 'bar',
            categoryPercentage: 0.2,
            barThickness: 10,
            backgroundColor: this.greenColor,
            data: dataChart.input
          }
        ]
      }
      const dataTooltip = {
        enabled: true,
        mode: 'single',
        displayColors: false,
        callbacks: {
          title: () => null,
          label: (t) => {
            const day = 'Hari ke : ' + t.xLabel
            const ip = 'IP : ' + this.formatFloat(t.yLabel)
            return [day, ip]
          }
        }
      }
      const dataLegend = {
        display: true,
        position: 'bottom',
        labels: {
          generateLabels: () => {
            return [
              {
                text: "Actual Value",
                fillStyle: this.greenColor
              }
            ]
          }
        }
      }

      switch (type) {
      case 'ip':
        this.dataIP = buildData
        this.barLineOptionIP = JSON.parse(JSON.stringify(this.barLineOptions))
        this.barLineOptionIP.tooltips = dataTooltip
        this.barLineOptionIP.legend = dataLegend
        this.barLineOptionIP.scales.yAxes[0].ticks.max = maxInput
        // this.barLineOptionIP.scales.yAxes[0].ticks.stepSize = 2.5
        this.barLineOptionIP.title.text = 'Index Prestasi (IP)'
        break
      default:
        break
      }
    }
  }
}
</script>

<style>
  .small {
    border: 1px solid #C2D6CE;
    border-radius: 16px;
    padding: 30px 35px;
    margin:  50px auto;
  }
</style>
