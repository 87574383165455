<template>
  <div class="uk-card uk-card-default kandang-card">
    <TableDigitalConcent :data-cage="dataCage" />
  </div>
</template>
<script>
import TableDigitalConcent from './TableDigitalConcent.vue'

export default {
  components: {
    TableDigitalConcent
  }
}
</script>
