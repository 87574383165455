<template>
  <div class="uk-card uk-card-default kandang-card">
    <div
      class="uk-child-width-expand@s uk-grid-small"
      uk-grid
    >
      <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Kode Kandang
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="dataCage.cage_code"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Nama Kandang
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="dataCage.name"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Nama Peternakan
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              class="uk-input"
              :value="dataCage.farm ? dataCage.farm.name : '-' "
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Status
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div :class="dataCage.status == true ? 'status-active' : 'status-inactive'">
                {{ dataCage.status == true ? 'Aktif' : 'Tidak Aktif' }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Anak Kandang
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="dataCage.cage_staff"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Pengawas Lapangan
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="dataCage.field_supervisor"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Cabang
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="dataCage.branch"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Kota/Kabupaten
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <div class="uk-form-controls">
              <input
                v-model="dataCage.city"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Alamat
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <textarea
              v-model="dataCage.address"
              :class="{'uk-textarea': true}"
              rows="5"
              disabled
            />
          </div>
        </div>
      <!-- TODO -->
      <!-- <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">Titik Lokasi</div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
          <div class="uk-form-controls">
            <input
              class="uk-input"
              v-model="dataCage.location"
              type="text"
              disabled
            >
          </div>
        </div>
      </div> -->
      <!-- <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">Merek DOC</div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
          <div class="uk-form-controls">
            <input
              class="uk-input"
              v-model="dataCage.chick_source"
              type="text"
              disabled
            >
          </div>
        </div>
      </div> -->
      </div>
      <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Luas Lahan (m2)
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="dataCage.total_area"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Tipe Kandang
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <div class="uk-form-controls">
              <input
                v-model="dataCage.cage_category"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Daya Tampung (ekor)
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="dataCage.chick_capacity"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Tingkat
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <div class="uk-form-controls">
              <input
                v-model="dataCage.floor"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Panjang (m)
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="dataCage.height"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Lebar (m)
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="dataCage.width"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Kipas
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="dataCage.fan"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Pemanas
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="dataCage.heater"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Pajak Penghasilan
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <div class="uk-form-controls">
              <input
                v-model="tax_choose.name"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
        </div>
        <div
          v-if="tax_choose && tax_choose.show"
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            NPWP
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="dataCage.npwp"
              v-mask="'##.###.###.#-###.###'"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Akuisisi
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="dataCage.acquisition_name"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
      </div>
    </div>
    <div class="uk-width-1-1 uk-text-right uk-margin-top">
      <button
        class="uk-button uk-button-danger uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
        type="button"
        @click="showDeleteConfirmModal"
      >
        <img
          v-lazy="`${images}/icon/trash-white.svg`"
          alt=""
          class="uk-margin-small-right"
        >
        Hapus Kandang
      </button>
      <button
        class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-left-med uk-margin-right-med uk-margin-remove-left@s uk-margin-remove-right@s uk-width-expand uk-width-auto@s"
        type="button"
        @click="goToEdit"
      >
        <img
          v-lazy="`${images}/icon/edit.svg`"
          alt=""
          class="uk-margin-small-right"
        >
        Ubah Data Kandang
      </button>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import { dateParanjeCompleteString } from '@/utils/formater'

export default {
  props: {
    dataCage: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      tax_list: [
        {
          name: 'Pribadi (PPh 21) dengan NPWP',
          show: true,
          value: "2.5"
        },
        {
          name: 'Pribadi (PPh 21) tanpa NPWP',
          show: false,
          value: '3'
        },
        {
          name: 'Badan (PPh 23) dengan NPWP',
          show: true,
          value: '2'
        },
        {
          name: 'Badan (PPh 23) tanpa NPWP',
          show: false,
          value: '4'
        }
      ],
      tax_choose: {}
    }
  },
  watch: {
    dataCage() {
      if (this.dataCage) {
        this.dataCage.join_date = dateParanjeCompleteString(this.dataCage.join_date)
        if (this.dataCage.income_tax) {
          this.tax_choose = this.tax_list.find(i => i.value == this.dataCage.income_tax) || null
        }
      }
    }
  },
  mounted() {
    console.log(this.dataCage)
  },
  methods: {
    ...mapMutations({
      setModalDelete: 'cage/SET_MODAL_DELETE'
    }),
    showDeleteConfirmModal() {
      window.UIkit.modal('#modal-delete-confirm').show()
      this.setModalDelete(this.dataCage)
    },
    goToEdit() {
      window.location.href = `/admin/kandang-ayam/edit/${this.dataCage.id}`
    }
  }
}
</script>

<style scoped>
.status-active {
  border-radius: 8px;
  background: rgba(20, 182, 20, 0.16);
  border: 1px solid rgba(20, 182, 20, 0.2);
  color: #14b614;
  text-align: center;
  padding: 5px 0;
  width: 100px;
}
.status-inactive {
  border-radius: 8px;
  background: rgba(237, 94, 94, 0.16);
  border: 1px solid rgba(237, 94, 94, 0.2);
  color: #ed5e5e;
  text-align: center;
  padding: 5px 0;
  width: 100px;
}
</style>
