<template>
  <div class="uk-card uk-card-default kandang-card">
    <TableRiwayatSiklus :cycles-no-limit="cyclesNoLimit" />
    <div
      class="uk-child-width-expand@s uk-grid-small uk-margin-top"
      uk-grid
    >
      <div class="uk-width-1-1 uk-width-1-2@s uk-width-1-2@l uk-width-1-2@xl">
        <router-link
          class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
          to="/admin/kandang-ayam"
        >
          <img
            v-lazy="`${images}/icon/back.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Kembali
        </router-link>
      </div>
      <div class="uk-width-1-1 uk-width-1-2@s uk-width-1-2@l uk-width-1-2@xl uk-text-right">
        <button
          class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
          type="button"
          @click="showDeleteConfirmModal"
        >
          <img
            v-lazy="`${images}/icon/trash-white.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Hapus Kandang
        </button>
        <!-- <button class="uk-button uk-button-primary uk-margin-left" type="button" @click="goToEdit">
          <img v-lazy="`${images}/icon/edit.svg`" alt="" class="uk-margin-small-right">
          Ubah
        </button> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import TableRiwayatSiklus from './TableRiwayatSiklus'
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  components: {
    TableRiwayatSiklus
  },
  props: {
    cyclesNoLimit: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE
    }
  },
  methods: {
    ...mapMutations({
      setModalDelete: 'cage/SET_MODAL_DELETE'
    }),
    goToBack() {
      window.location.href = '/admin/kandang-ayam'
    },
    showDeleteConfirmModal() {
      window.UIkit.modal('#modal-delete-confirm').show()
      this.setModalDelete(this.cageDetail.data)
    }
  }
}
</script>
