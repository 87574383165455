<template>
  <div
    id="modal-delete-confirm"
    uk-modal
    esc-close="false"
    bg-close="false"
    container="#container-modal-delete-cage"
  >
    <div class="uk-modal-dialog uk-modal-body">
      <div class="uk-modal-title uk-text-danger">
        <img
          v-lazy="`${images}/icon/warning.svg`"
          alt=""
          class="uk-margin-small-right"
        >
        Hapus Kandang Ayam
      </div>
      <p style="font-size:15px">
        Apakah kamu yakin ingin menghapus <br> "{{ modalDelete.name }}" ? <br> Tindakan ini akan menghapus data Kegiatan Kandang di dalam Kandang tersebut
      </p>
      <div style="padding-top:20px">
        <button
          class="uk-button uk-button-default"
          type="button"
          :disabled="is_loading"
          @click="handleDelete"
        >
          Ya, hapus
        </button>
        <button
          class="uk-button uk-button-primary uk-margin-left uk-modal-close"
          type="button"
          :disabled="is_loading"
        >
          Tidak
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import {
  notificationSuccess
} from '@/utils/notification'

export default {
  data() {
    return {
      is_loading: false,
      images: PREFIX_IMAGE
    }
  },
  computed : {
    ...mapGetters({
      modalDelete: 'cage/modalDelete',
      meta: 'cage/meta'
    })
  },
  methods: {
    ...mapActions({
      deleteCage: 'cage/deleteCage',
      getCage: 'cage/getCage'
    }),
    async handleDelete () {
      try {
        this.is_loading = true
        await this.deleteCage(this.modalDelete.id)
        const res = await this.$store.getters['cage/getDeleteCageMsg']
        if (res.message === 'OK') {
          notificationSuccess(`Hapus Kandang Ayam "${this.modalDelete.name}" Berhasil`)
        } else {
          window.UIkit.modal('#modal-delete-failed').show()
        }
        await this.getCage(this.meta)
        this.is_loading = false
        window.UIkit.modal('#modal-delete-confirm').hide()
      } catch (error) {
        console.log(error.response)
        this.is_loading = false
      }
      this.is_loading = false
    }
  }
}
</script>
