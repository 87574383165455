<template>
  <div class="uk-card uk-card-default kandang-card">
    <div
      class="uk-child-width-expand@s uk-grid-small"
      uk-grid
    >
      <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Siklus Ke
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="data.cylce_now"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Mulai Persiapan
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="data.start_preparation"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Chick In
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="data.chick_in"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
      </div>
      <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Mulai Panen
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="data.start_harvest"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Chick Out
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="data.chick_out"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Sanitasi
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="data.sanitation"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div
      class="uk-child-width-expand@s uk-grid-small"
      uk-grid
    >
      <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Tahapan
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="activity"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Populasi (ekor)
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="population"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Deplesi (ekor)
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="dead_chicken"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <!-- <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">Bobot Rataan</div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  class="uk-input"
                  v-model="average_weight"
                  type="text"
                  disabled
                >
              </div>
            </div> -->
      </div>
      <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Bobot Sampel (gr)
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="weight_sample"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Stok Pakan (sak)
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input
              v-model="food_stock"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <!-- <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">Index Prestasi (IP)</div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  class="uk-input"
                  v-model="ip"
                  type="text"
                  disabled
                >
              </div>
            </div> -->
      </div>
    </div>
    <TableSiklus />
    <div
      class="uk-child-width-expand@s uk-grid-small uk-margin-top"
      uk-grid
    >
      <div class="uk-width-1-1 uk-width-1-4@s uk-width-1-4@m uk-width-1-4@l">
        <router-link
          class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
          to="/admin/kandang-ayam"
        >
          <img
            v-lazy="`${images}/icon/back.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Kembali
        </router-link>
      </div>
      <div class="uk-width-1-1 uk-width-1-1@s uk-width-3-4@m uk-width-3-4@l uk-text-right">
        <button
          class="uk-button uk-button-default uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-width-expand uk-width-auto@s"
          type="button"
          @click="csvExport"
        >
          <img
            v-lazy="`${images}/icon/export.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Export
        </button>
        <button
          class="uk-button uk-button-default uk-margin-top uk-margin-remove-top-med uk-margin-left-med uk-margin-right-med uk-margin-remove-left@s uk-margin-remove-right@s uk-width-expand uk-width-auto@s"
          type="button"
          @click="showLogHistory"
        >
          <img
            v-lazy="`${images}/icon/log-history.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Log History
        </button>
        <button
          class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-width-expand uk-width-auto@s"
          type="button"
          @click="goToEdit"
        >
          <img
            v-lazy="`${images}/icon/edit.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Ubah Data Siklus
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import TableSiklus from './TableSiklus'
import { dateParanjeCompleteString, float3Decimals, thousandSeparator } from '@/utils/formater'
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  components: {
    TableSiklus
  },
  props: {
    dataCycle: { // TODO: ini knpa pada pkek lempar-lemparan props si? kan bisa get langsung dari mapGetters
      required: true,
      type: Object
    },
    cyclesNoLimit: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      data: {},
      activity: null,
      population: 0,
      dead_chicken: 0,
      weight_sample: 0,
      food_stock: 0,
      cageDetail: null,
      average_weight: 0,
      ip: 0
    }
  },
  computed: {
    ...mapGetters({
      cycles: 'cycle/cycles',
      getCageDetail: 'cage/cage'
    })
  },
  watch: {
    getCageDetail() {
      this.cageDetail = this.getCageDetail
    },
    dataCycle() {
      const startPrepare = dateParanjeCompleteString(this.dataCycle.cycle.start_preparation)
      const startHarvest = dateParanjeCompleteString(this.dataCycle.cycle.start_harvest)
      const chickIn = dateParanjeCompleteString(this.dataCycle.cycle.chick_in)
      const chickOut = dateParanjeCompleteString(this.dataCycle.cycle.chick_out)
      const sanitation = dateParanjeCompleteString(this.dataCycle.cycle.sanitation)

      this.dataCycle.cycle.start_preparation = startPrepare !== '01 Januari 1' ? startPrepare : ''
      this.dataCycle.cycle.start_harvest = startHarvest !== '01 Januari 1' ? startHarvest : ''
      this.dataCycle.cycle.chick_in = chickIn !== '01 Januari 1' ? chickIn : ''
      this.dataCycle.cycle.chick_out = chickOut !== '01 Januari 1' ? chickOut : ''
      this.dataCycle.cycle.sanitation = sanitation !== '01 Januari 1' ? sanitation : ''
      this.average_weight = this.dataCycle.average_weight ? this.dataCycle.average_weight : 0
      const cycleNow = this.dataCycle.log_cycle_count ? this.dataCycle.log_cycle_count : 0
      this.ip = this.dataCycle.ip ? this.dataCycle.ip : 0

      this.data = {
        cylce_now: cycleNow,
        start_preparation: this.dataCycle.cycle.start_preparation,
        chick_in: this.dataCycle.cycle.chick_in,
        start_harvest: this.dataCycle.cycle.start_harvest,
        chick_out: this.dataCycle.cycle.chick_out,
        sanitation: this.dataCycle.cycle.sanitation
      }
      this.weight_sample = this.formatFloat(this.dataCycle.chick_body_weight) || 0
      this.activity = this.dataCycle.current_activity_name || '-'
      this.population = this.dataCycle.chick_left || 0
      this.dead_chicken = this.dataCycle.depletion || 0
    }
    // cycles() {
    // if (this.cycles.data && this.cycles.data.length>0) {
    //   const currentCycle = this.cycles.data[this.cycles.data.length-1]
    //   this.weight_sample = this.formatFloat(currentCycle.group_body_weight_actual) || 0
    //   this.activity = currentCycle.activity_name

    //   let dead_chicken = 0
    //   this.cycles.data.forEach(data => {
    //     dead_chicken += data.group_deplesi_died + data.group_deplesi_culling
    //   })
    //   this.population = this.dataCycle.cycle.population ? this.dataCycle.cycle.population - dead_chicken : 0
    //   this.dead_chicken = dead_chicken
    // }
    // }
  },
  methods: {
    ...mapActions({
      getCycleNoLimit: 'cycle/getCycleNoLimit'
    }),
    ...mapMutations({
      setModalDelete: 'cage/SET_MODAL_DELETE'
    }),
    formatFloat(number) {
      return float3Decimals(number)
    },
    goToEdit() {
      window.location.href = `/admin/kandang-ayam/edit-siklus/${this.$route.params.id}`
    },
    showLogHistory() {
      window.UIkit.modal('#modal-log-history').show()
    },
    async csvExport() {
      await this.getCycleNoLimit({cage_id: this.getCageDetail.data.id, log_cycle_id: ''})
      const arrData = {
        detail_cage: this.cyclesNoLimit ? this.cyclesNoLimit.detail_cage : {},
        cycle: this.cyclesNoLimit ? this.cyclesNoLimit.cycle : []
      }
      let newArray1 = []
      const newArray = []
      let newData = {}
      let csvContent = "data:text/csv;charset=utf-8,"

      newArray1 = [
        {
          title: 'Nama Kandang :',
          value: arrData.detail_cage.name
        },
        {
          title: 'Siklus ke :',
          value: arrData.detail_cage.log_cycle_count
        },
        {
          title: 'Mulai Persiapan :',
          value: dateParanjeCompleteString(arrData.detail_cage.start_preparation)
        },
        {
          title: 'Chick in :',
          value: dateParanjeCompleteString(arrData.detail_cage.chick_in)
        },
        {
          title: 'Mulai panen :',
          value: dateParanjeCompleteString(arrData.detail_cage.start_harvest)
        },
        {
          title: 'Chick out :',
          value: dateParanjeCompleteString(arrData.detail_cage.chick_out)
        },
        {
          title: 'Sanitasi :',
          value: dateParanjeCompleteString(arrData.detail_cage.sanitation)
        }
      ]
      csvContent += [
        Object.keys([]).join("\n"),
        ...newArray1.map((item) => Object.values(item).join(";")),
        Object.keys([]).join("\n"),
        Object.keys([]).join("\n"),
        Object.keys([]).join("\n")
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "")

      if (arrData.cycle !== null) {
        arrData.cycle.map(i => {
          newData = {
            'Tanggal': dateParanjeCompleteString(i.workdate),
            'Tahapan': i.activity_name,
            'Pakan (sak)': float3Decimals(i.pakan_sak),
            'Pakan (kg)': i.group_pakan_actual,
            'Ayam Mati (ekor)': i.group_deplesi_died,
            'Culling (ekor)': i.group_deplesi_culling,
            'Panen (ekor)': '',
            'Sisa Ayam': i.chic_left,
            'Bobot (Act)': float3Decimals(i.group_body_weight_actual),
            'Bobot (Std)': thousandSeparator(i.group_body_weight_standard),
            'Daily Intake (Act)': float3Decimals(i.group_daily_intake_actual),
            'Daily Intake (Std)': thousandSeparator(i.group_daily_intake_standard),
            'Feed Intake (Act)': float3Decimals(i.group_feed_intake_actual),
            'Feed Intake (Std)': thousandSeparator(i.group_feed_intake_standard),
            'FCR (Act)': float3Decimals(i.group_fcr_actual),
            'FCR (Std)': float3Decimals(i.group_fcr_standard),
            'Cum Dep (Act)': float3Decimals(i.group_cum_dep_actual),
            'Cum Dep (Std)': float3Decimals(i.group_cum_dep_standard),
            'Cum Dep (Ekor)': float3Decimals(i.group_cum_dep_left),
            'Indeks Prestasi': float3Decimals(i.ip_actual)
          }
          newArray.push(newData)
        })
        csvContent += [
          Object.keys(newArray[0]).join(";"),
          ...newArray.map(item => Object.values(item).join(";"))
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "")
      }

      const data = encodeURI(csvContent)
      const link = document.createElement("a")
      link.setAttribute("href", data)
      link.setAttribute("download", `Siklus Aktif ke ${arrData.detail_cage.log_cycle_count} Kandang ${arrData.detail_cage.name} .csv`)
      link.click()
    }
  }
}
</script>
