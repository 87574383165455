<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto">
      <table
        style="table-layout:fixed"
        class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider"
      >
        <thead class="thead-table-paranje">
          <tr>
            <th class="uk-width-small uk-table-expand">
              <span class="uk-text-bold">Siklus Ke </span>
            </th>
            <th class="uk-width-small uk-table-expand">
              <span class="uk-text-bold">Kode Siklus</span>
            </th>
            <th class="uk-width-small uk-table-expand">
              <span class="uk-text-bold">Diubah Oleh</span>
            </th>
            <th class="uk-width-small uk-table-expand">
              <span class="uk-text-bold">Role</span>
            </th>
            <th class="uk-width-small uk-text-center">
              <span class="uk-text-bold">Aksi</span>
            </th>
          </tr>
        </thead>
        <template>
          <tbody v-if="digitalConcent.data.data.length > 0">
            <tr
              v-for="(cyc, i) in digitalConcent.data.data"
              :key="i"
            >
              <td>{{ cyc.cycle_count || '-' }}</td>
              <td>{{ cyc.cycle_code || '-' }}</td>
              <td>{{ cyc.user_name || '-' }}</td>
              <td>{{ cyc.user_role || '-' }}</td>
              <td class="uk-flex uk-flex-center">
                <img
                  v-lazy="`${images}/icon/export.svg`"
                  alt=""
                  class="img-click uk-margin-small-right"
                  @click="fileExport(cyc.log_cycle_digital_consent_id)"
                >
              </td>
            </tr>
          </tbody>
          <loading-table
            v-else-if="is_loading" 
            :colspan="5"
          />
          <empty-table
            v-else
            :colspan="5"
          />
        </template>
      </table>
    </div>
    <pagination
      :total-data="digitalConcent.data.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="digitalConcent.data.meta.current_page"
      :current-limit="digitalConcent.data.meta.limit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import { PREFIX_IMAGE } from '@/utils/constant'
import Pagination from '@/components/globals/Pagination'
import { stringNotifDanger } from '@/utils/notification'

export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination
  },
  props: {
    dataCage: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      is_loading:false
    }
  },
  computed : {
    ...mapGetters({
      cage: 'cage/cage',
      digitalConcent: 'cage/digitalConcent',
      metaDigitalConcent: 'cage/metaDigitalConcent',
      fileExportDigitalConcent: 'cage/downloadDigitalConcent'
    })
  },
  watch: {
    async metaDigitalConcent() {
      await this.getDigitalConcent(this.$route.params.id+'?limit='+this.metaDigitalConcent.limit+"&page="+this.metaDigitalConcent.page)
    }
  },
  async mounted() {
    await this.getDigitalConcent(this.$route.params.id+'?limit='+this.metaDigitalConcent.limit+"&page="+this.metaDigitalConcent.page)
    this.is_loading = false
  },
  methods: {
    ...mapActions({
      getDigitalConcent: 'cage/getDigitalConcent',
      downloadDigitalConcent: 'cage/getDigitalConcentPdf'
    }),
    ...mapMutations({
      setmetaDigitalConcent: 'cage/SET_META_DIGITAL_CONCENT'
    }),
    changeLimit(e) {
      this.setmetaDigitalConcent({
        ...this.metaDigitalConcent,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setmetaDigitalConcent({
        ...this.metaDigitalConcent,
        page: value
      })
    },
    async fileExport(lc) {
      await this.downloadDigitalConcent(`${lc}?mitra_name=${this.cage.data.farm.name}`)
      if(this.fileExportDigitalConcent){
        const file = new Blob([this.fileExportDigitalConcent], {type: 'application/pdf'})
        const fileURL = URL.createObjectURL(file)
        const link = document.createElement('a')
        link.href = fileURL
        link.setAttribute('download', 'digital-concent-download')
        link.click()
      } else {
        stringNotifDanger('Gagal Mengunduh Lembar Persetujuan!')
      }
    }
  }
}
</script>

<style scoped>
.uk-width-small {
  width: 160px;
  padding: 18.5px 12px;
  vertical-align: inherit;
}
</style>
