<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto">
      <table
        style="table-layout:fixed"
        class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider"
      >
        <thead class="thead-table-paranje">
          <tr>
            <th class="uk-width-small uk-text-center uk-table-expand">
              <span class="uk-text-bold">Siklus Ke </span>
            </th>
            <th class="uk-width-small uk-text-center uk-table-expand">
              <span class="uk-text-bold">Mulai Persiapan</span>
            </th>
            <th class="uk-width-small uk-text-center uk-table-expand">
              <span class="uk-text-bold">Chick In</span>
            </th>
            <th class="uk-width-small uk-text-center uk-table-expand">
              <span class="uk-text-bold">Mulai Panen</span>
            </th>
            <th class="uk-width-small uk-text-center uk-table-expand">
              <span class="uk-text-bold">Chick Out</span>
            </th>
            <th class="uk-width-small uk-text-center uk-table-expand">
              <span class="uk-text-bold">Populasi (ekor)</span>
            </th>
            <th class="uk-width-small uk-text-center uk-table-expand">
              <span class="uk-text-bold">Bobot Sampel (gr)</span>
            </th>
            <th class="uk-width-small uk-text-center uk-table-expand">
              <span class="uk-text-bold">Indeks Prestasi (IP)</span>
            </th>
            <th class="uk-width-small uk-text-center">
              <span class="uk-text-bold">Aksi</span>
            </th>
          </tr>
        </thead>
        <template v-if="!is_loading">
          <tbody v-if="cycleHistory.data && cycleHistory.data.length>0">
            <tr
              v-for="(cyc, i) in cycleHistory.data"
              :key="i"
            >
              <td>{{ cyc.cycle_count }}</td>
              <td>{{ formatDate(cyc.start_preparation) || '-' }}</td>
              <td>{{ formatDate(cyc.chick_in) || '-' }}</td>
              <td>{{ formatDate(cyc.start_harvest) || '-' }}</td>
              <td>{{ formatDate(cyc.chick_out) || '-' }}</td>
              <td>{{ cyc.population || '-' }}</td>
              <td>{{ cyc.weight_sample || '-' }}</td>
              <td>{{ formatFloat(cyc.ip) || '-' }}</td>
              <td class="uk-flex uk-flex-center">
                <img
                  v-lazy="`${images}/icon/export.svg`"
                  alt=""
                  class="img-click uk-margin-small-right"
                  @click="csvExport(cyc)"
                >
                <img
                  v-lazy="`${images}/icon/eye.svg`"
                  alt=""
                  class="img-click"
                  @click="showDetail(cyc, i)"
                >
              </td>
            </tr>
          </tbody>
          <empty-table
            v-else
            :colspan="9"
          />
        </template>
        <template v-else>
          <loading-table :colspan="9" />
        </template>
      </table>
    </div>
    <pagination
      :total-data="cycleHistory.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="metaHistory.page"
      :current-limit="metaHistory.limit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import { STATUS } from '@/utils/constant'
import { PREFIX_IMAGE } from '@/utils/constant'
import { dateUtcParanjeString, dateParanjeCompleteString, float3Decimals, thousandSeparator } from '@/utils/formater'
import Pagination from '@/components/globals/Pagination'

export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination
  },
  props: {
    cyclesNoLimit: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      status: STATUS,
      images: PREFIX_IMAGE
    }
  },
  computed : {
    ...mapGetters({
      cycleHistory: 'cycle/cycleHistory',
      is_loading: 'cycle/cycles_loading',
      metaHistory: 'cycle/metaHistory'
    })
  },
  watch: {
    async metaHistory() {
      await this.getCycleHistory({cage_id: this.$route.params.id, meta: this.metaHistory})
    }
  },
  methods: {
    ...mapActions({
      getCycleHistory: 'cycle/getCycleHistory',
      getCycleNoLimit: 'cycle/getCycleNoLimit'
    }),
    ...mapMutations({
      setMetaHistory: 'cycle/SET_META_HISTORY'
    }),
    changeLimit(e) {
      this.setMetaHistory({
        ...this.metaHistory,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMetaHistory({
        ...this.metaHistory,
        page: value
      })
    },
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    formatFloat(number, limit) {
      return float3Decimals(number, limit)
    },
    async csvExport(dataCyc) {
      await this.getCycleNoLimit({cage_id: this.$route.params.id, log_cycle_id: dataCyc.id})
      const arrData = {
        detail_cage: this.cyclesNoLimit ? this.cyclesNoLimit.detail_cage : {},
        cycle: this.cyclesNoLimit ? this.cyclesNoLimit.cycle : []
      }
      let newArray1 = []
      const newArray = []
      let newData = {}
      let csvContent = "data:text/csv;charset=utf-8,"
      console.log('arrData: ', arrData)
      console.log('dataCyc: ', dataCyc)
      newArray1 = [
        {
          title: 'Nama Kandang :',
          value: arrData.detail_cage.name
        },
        {
          title: 'Siklus ke :',
          value: arrData.detail_cage.log_cycle_count
        },
        {
          title: 'Mulai Persiapan :',
          value: dateParanjeCompleteString(arrData.detail_cage.start_preparation)
        },
        {
          title: 'Chick in :',
          value: dateParanjeCompleteString(arrData.detail_cage.chick_in)
        },
        {
          title: 'Mulai panen :',
          value: dateParanjeCompleteString(arrData.detail_cage.start_harvest)
        },
        {
          title: 'Chick out :',
          value: dateParanjeCompleteString(arrData.detail_cage.chick_out)
        },
        {
          title: 'Sanitasi :',
          value: dateParanjeCompleteString(arrData.detail_cage.sanitation)
        }
      ]
      csvContent += [
        Object.keys([]).join("\n"),
        ...newArray1.map((item) => Object.values(item).join(";")),
        Object.keys([]).join("\n"),
        Object.keys([]).join("\n"),
        Object.keys([]).join("\n")
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "")

      if (arrData.cycle !== null) {
        arrData.cycle.map(i => {
          newData = {
            'Tanggal': dateParanjeCompleteString(i.workdate),
            'Tahapan': i.activity_name,
            'Pakan (sak)': float3Decimals(i.pakan_sak),
            'Pakan (kg)': i.group_pakan_actual,
            'Ayam Mati (ekor)': i.group_deplesi_died,
            'Culling (ekor)': i.group_deplesi_culling,
            'Panen (ekor)': '',
            'Sisa Ayam': i.chic_left,
            'Bobot (Act)': float3Decimals(i.group_body_weight_actual),
            'Bobot (Std)': thousandSeparator(i.group_body_weight_standard),
            'Daily Intake (Act)': float3Decimals(i.group_daily_intake_actual),
            'Daily Intake (Std)': thousandSeparator(i.group_daily_intake_standard),
            'Feed Intake (Act)': float3Decimals(i.group_feed_intake_actual),
            'Feed Intake (Std)': thousandSeparator(i.group_feed_intake_standard),
            'FCR (Act)': float3Decimals(i.group_fcr_actual),
            'FCR (Std)': float3Decimals(i.group_fcr_standard),
            'Cum Dep (Act)': float3Decimals(i.group_cum_dep_actual),
            'Cum Dep (Std)': float3Decimals(i.group_cum_dep_standard),
            'Cum Dep (Ekor)': float3Decimals(i.group_cum_dep_left),
            'Indeks Prestasi': float3Decimals(i.ip_actual)
          }
          newArray.push(newData)
        })
        csvContent += [
          Object.keys(newArray[0]).join(";"),
          ...newArray.map(item => Object.values(item).join(";"))
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "")
      }

      const data = encodeURI(csvContent)
      const link = document.createElement("a")
      link.setAttribute("href", data)
      link.setAttribute("download", `Riwayat Siklus ke ${dataCyc.cycle_count} Kandang ${arrData.detail_cage.name} .csv`)
      link.click()
    },
    showDetail(cyc, i) {
      const cycleCount = i + 1
      cyc['cycle_count'] = cycleCount
      this.$router.push(`/admin/kandang-ayam/detail/riwayat-siklus/${cyc.id}`)
    }
  }
}
</script>

<style scoped>
.uk-width-small {
  width: 160px;
  padding: 18.5px 12px;
  vertical-align: inherit;
}
</style>
